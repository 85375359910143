import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Switch } from "@progress/kendo-react-inputs";
import BuzopsIntakeForm from "components/BuzopsForms/BuzopsOnlineIntakeForms/BuzopsIntakeForm";
import ViewClientResponse from "components/BuzopsForms/ViewClientResponse";
import withNotification from "components/Hoc/withNotification";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import moment from "moment";
import React, { useEffect } from "react";
import { useApiClient } from "services/axios-service-utils";
import { checkHasSMSEnable } from "utils";
import { filterBy } from "@progress/kendo-data-query";
import "../../../scss/forms/application.scss";
import {
  CommunicationPreferenceList,
  CommunicationPreferenceOnlyEmail,
  FormTemplateCategoryIntEnum,
  FormTemplateStatusIntEnum,
} from "utils/form-utils";
import useState from "react-usestateref";
import CustomCalendar, { FilterOptions } from "atoms/CustomCalendar";
import { TenantService } from "services/tenant/index.service";
import { useDispatch, useSelector } from "react-redux";
import { setUserConfiguration } from "redux-slices/userSlice";
const DateCell = (props: any) => {
  return (
    <td>
      {props?.dataItem[props?.field]
        ? moment(props?.dataItem[props?.field]).format("L")
        : "NA"}
    </td>
  );
};
const initialDialog = {
  enforce: false,
  share: false,
};
const initailDataValue = {
  CommunicationPreference: 0,
  Enforce: false,
};
const Forms = (props: any) => {
  const dispatch = useDispatch()
  const { axiosRequest } = useApiClient();
  const [formsList, setFormsList] = useState([]);
  const [origFormsList, setOrigFormsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [savedFormsList, setSavedFormsList] = useState([]);
  const [fromDateToDate, setFromDateToDate, fromDateToDateRef] = useState({
    from: moment().startOf("year").format("YYYY-MM-DDTHH:mm:ss"),
    to: moment().endOf("month").endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  });
  const userInfo = useSelector(
    (state: any) => state?.userConfiguration?.userDetails
  );
  const [visibility, setVisibility] = useState({
    saveResponse: false,
    viewResponse: false,
  });
  const [shareData, setShareData] = useState(initailDataValue);
  const [showDialog, setShowDialog] = useState(initialDialog);
  useEffect(() => {
    fetchAllApis();
  }, []);

  const toggleDialog = () => {
    setShowDialog(initialDialog);
  };

  const fetchAllApis = async () => {
    setLoading(true);
    await fetchFormsList();
    await fetchFormsInstancesList();
    setLoading(false);
  };

  const errorCallBack = (message: string) => {
    props?.handleNotificationMessage(message, "error");
  };

  const fetchFormsList = async () => {
    // need to modify paload based on API
    const req: any = {
      SortDirection: "ASC",
      SortBy: "FormName",
      PageSize: 32767,
      PageNumber: 0,
      Status: FormTemplateStatusIntEnum["PUBLISHED"],
      FormCategory: FormTemplateCategoryIntEnum["GENERAL"],
      UserId: !props?.staffPage ? props?.userDetails?.UserId : undefined,
      IsStaff: props?.staffPage,
    };
    await axiosRequest.post("Forms/FormTemplate/filter", req, {
      successCallBack: (response: any) => {
        const records = response.data?.Items?.filter(
          (i: any) => !i?.IsFormSubmitted
        );
        setFormsList(records || []);
        setOrigFormsList(records || []);
      },
      errorCallBack: (response: any) => {
        errorCallBack(
          response?.response?.data?.Messages?.[0] || "Internal server error"
        );
      },
    });
  };

  const fetchFormsInstancesList = async () => {
    // need to modify paload
    let req: any = {
      SortDirection: "DESC",
      SortBy: "FormInstanceId",
      PageSize: 32767,
      PageNumber: 0,
      // FormTemplateId: "tbKy",
      // UserId: "tQ==",
      FromDate: fromDateToDateRef?.current?.from,
      ToDate: fromDateToDateRef?.current?.to,
      UserId: props?.userDetails?.UserId,
    };
    await axiosRequest.post("Forms/FormInstance/filter", req, {
      successCallBack: (response: any) => {
        const data = response.data?.Items || [];
        setSavedFormsList(data);
        // return response.data?.Items || [];
      },
      errorCallBack: (response: any) => {
        errorCallBack(
          response?.response?.data?.Messages?.[0] || "Internal server error"
        );
      },
    });
  };
  const setFromDateToDateFunc = async (val: any) => {
    setFromDateToDate(val);
    await fetchFormsInstancesList();
  };
  const handleShareForm = async () => {
    if (!shareData?.CommunicationPreference) {
      props?.handleNotificationMessage("Please Select Communication", "error");
      return;
    }
    const req = {
      FormTemplateId: selectedItem?.FormTemplateId,
      UserId: props?.userDetails?.UserId,
      CommunicationPreference: shareData?.CommunicationPreference,
      IsEnforce: shareData?.Enforce,
    };
    setBtnLoading(true);
    await axiosRequest.post("Forms/FormTemplate/assign", req, {
      successCallBack: (response: any) => {
        setBtnLoading(false);
        props?.handleNotificationMessage(
          "Form Template shared successfully",
          "success"
        );
        toggleDialog();
        setSelectedItem(null);
      },
      errorCallBack: (response: any) => {
        setBtnLoading(false);
        props?.handleNotificationMessage(
          response?.response?.data?.Messages?.[0] || "Internal server error",
          "error"
        );
      },
    });
  };
  const handleEnforceClient = async () => {
    const req = {
      FormTemplateId: selectedItem?.FormTemplateId,
      UserId: props?.userDetails?.UserId,
      IsEnforce: true,
    };
    setBtnLoading(true);
    await axiosRequest.post("Forms/FormTemplate/assign", req, {
      successCallBack: (response: any) => {
        setBtnLoading(false);
        props?.handleNotificationMessage(
          "Form Template enforced successfully",
          "success"
        );
        toggleDialog();
        setSelectedItem(null);
      },
      errorCallBack: (response: any) => {
        setBtnLoading(false);
        props?.handleNotificationMessage(
          response?.response?.data?.Messages?.[0] || "Internal server error",
          "error"
        );
      },
    });
  };

  const ViewResponseAction = (propsData: any) => {
    return (
      <td>
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <BuzopsTooltip
            html={
              <BuzopsButton
                title="Saved Form"
                primary={true}
                // iconClass="fa fa-envelope"
                className="k-button-sm send-link-btn-affiliate"
                onClick={() => {
                  handleSelectForm(propsData?.dataItem);
                  handleView("viewResponse", propsData?.dataItem);
                }}
                label={"View Response"}
              />
            }
          />
        </div>
      </td>
    );
  };
  const handlePopUpWindow = (handleEvent: any) => {
    setVisibility(handleEvent);
    setSelectedItem(null)
    fetchAllApis();
  };

  const handleSelectForm = (dataItem: any) => {
    setSelectedItem(dataItem);
  };

  const filterChange = (e: any) => {
    const filteredData = filterData(e.filter);
    setFormsList(filteredData);
  };

  const filterData = (filter: any) => {
    const data = origFormsList.slice();
    return filterBy(data, filter);
  };

  const handleView = async (screen: string, item?: any) => {
    if (!item) {
      props?.handleNotificationMessage("Please select the form", "error");
      return;
    }
    setVisibility({ ...visibility, [screen]: true });
  };
  const handleViewDialog = async (screen: string, item?: any) => {
    if (!item) {
      props?.handleNotificationMessage("Please select the form", "error");
      return;
    }
    setShowDialog({ ...showDialog, [screen]: true });
  };
  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = (
      <div className="d-flex justify-content-between">
        <div>{itemProps?.dataItem?.FormName}</div>
        {itemProps?.dataItem?.IsEnforce && props?.enforceForm && (
          <div>
            <span className="badge badge-status-Expired">Required</span>
          </div>
        )}
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const handleChange = (val: any, name: string) => {
    setShareData({ ...shareData, [name]: val });
  };

  const handleCloseSavedResponse=async()=> {
    await  props?.onSuccessfullFormSubmit();
    handlePopUpWindow({ ...visibility, saveResponse: false })
  }


  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  if (visibility.saveResponse) {
    return (
      <>
        <h5 className="mb-3 bz_fs_1_5 affiliatemainheading">
          <button
            className="btn btn-primary bz-back-btn btn-sm mr-3"
            onClick={() => handleCloseSavedResponse()}
          >
            <i className="fas fa-arrow-left"></i>
          </button>
          &nbsp;{"Fill Form"}
        </h5>
        <BuzopsIntakeForm
          FormTemplate={selectedItem}
          userDetails={props?.userDetails}
          handleWindow={() =>() => handleCloseSavedResponse()}
        />
      </>
    );
  }

  if (visibility.viewResponse) {
    return (
      <>
        <h5 className="mb-3 bz_fs_1_5 affiliatemainheading">
          <button
            className="btn btn-primary bz-back-btn btn-sm mr-3"
            onClick={() =>
              handlePopUpWindow({ ...visibility, viewResponse: false })
            }
          >
            <i className="fas fa-arrow-left"></i>
          </button>
          &nbsp;{"View Saved Response"}
        </h5>
        <ViewClientResponse
          formInstance={selectedItem}
          handleWindow={() =>
            handlePopUpWindow({ ...visibility, viewResponse: false })
          }
        />
      </>
    );
  }
  const EnforcedForms = formsList?.filter((i: any) => i?.IsEnforce);
  return (
    <>
      {formsList?.length > 0 && (
        <>
          <div className="d-flex flex-column justify-content-start">
            <h5 className="my-2 bz_fs_1_5">Forms</h5>
            {!props?.staffPage && props?.enforceForm && EnforcedForms?.length > 0 && (
              <>
                <div className="alert alert-warning fade show" role="alert">
                  <span>
                    {`Action Required: Fill the required form. No further actions can be performed until the form filled.`}
                  </span>
                </div>
              </>
            )}
          </div>
          <div className="d-flex flex-wrap align-items-center mb-2">
            <DropDownList
              data={formsList}
              textField="FormName"
              dataItemKey="FormTemplateId"
              style={{ width: "250px" }}
              name="FormTemplateId"
              value={
                selectedItem
                  ? selectedItem
                  : {
                      FormTemplateId: 0,
                      FormName: "Please Select Form",
                    }
              }
              itemRender={itemRender}
              onChange={(e) => handleSelectForm(e.value)}
              filterable={true}
              onFilterChange={(e) => filterChange(e)}
            />
            {props?.staffPage && (
              <>
                <BuzopsTooltip
                  html={
                    <BuzopsButton
                      title="Share Form"
                      primary={true}
                      iconClass="fa fa-envelope"
                      className="k-button-sm send-link-btn-affiliate"
                      onClick={() => handleViewDialog("share", selectedItem)}
                      label={"Share"}
                    />
                  }
                />
                <BuzopsTooltip
                  html={
                    <BuzopsButton
                      title="Enforce"
                      primary={true}
                      // iconClass="fa fa-envelope"
                      className="k-button-sm send-link-btn-affiliate"
                      onClick={() => handleViewDialog("enforce", selectedItem)}
                      label={"Enforce"}
                    />
                  }
                />
              </>
            )}
            <BuzopsTooltip
              html={
                <BuzopsButton
                  title="Fill Form"
                  primary={true}
                  // iconClass="fa fa-envelope"
                  className="k-button-sm send-link-btn-affiliate"
                  onClick={() => handleView("saveResponse", selectedItem)}
                  label={"Fill Form"}
                />
              }
            />
          </div>
          <br />
        </>
      )}

      <div className="d-flex justify-content-between">
        <h5 className="my-2 bz_fs_1_5">Saved Responses</h5>
      </div>
      <div className="d-flex justify-content-start mb-2 mx-2">
        <span title="filter-dates" className="mr-2 ">
          {/* <div>Select Date</div> */}
          <CustomCalendar
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
            setFromDateToDate={setFromDateToDateFunc}
            selectedDateItem={FilterOptions.ThisYear}
            onlyPastDates
          />
        </span>
      </div>
      <Grid data={savedFormsList || []} className="bzc-grid">
        <Column
          field="FormName"
          title="Form Name"
          cell={({ dataItem }) => {
            return <td>{dataItem?.FormTemplate?.FormName}</td>;
          }}
        />
        <Column
          field="Description"
          title="Description"
          cell={({ dataItem }) => {
            return <td>{dataItem?.FormTemplate?.Description}</td>;
          }}
        />
        <Column
          field="FormCategory"
          title="FormCategory"
          cell={({ dataItem }) => {
            return <td>{dataItem?.FormTemplate?.FormCategory}</td>;
          }}
        />
        <Column field="CreateDateTime" title="Submitted Date" cell={DateCell} />
        <Column
          field="ReferredBy"
          title="Referred By"
          cell={({ dataItem }) => {
            return (
              <td>
                {dataItem?.Affiliate
                  ? `${dataItem?.User?.FirstName} ${dataItem?.User?.LastName}`
                  : `NA`}
              </td>
            );
          }}
        />
        <Column
          field="Action"
          cell={(props: any) => ViewResponseAction(props)}
        />
      </Grid>
      {/* {popUpWindow()} */}
      {showDialog?.enforce && (
        <Dialog title={"Please confirm"} onClose={toggleDialog}>
          <p style={{ margin: "25px", textAlign: "center" }}>
            Are you sure you want to Enforce this Form?
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={toggleDialog}
              disabled={btnLoading}
            >
              No
            </button>
            <BuzopsButton
              key={btnLoading?.toString()}
              onClick={() => handleEnforceClient()}
              label={"Yes"}
              className="mr-0"
              loading={btnLoading}
              disabled={btnLoading}
            />
          </DialogActionsBar>
        </Dialog>
      )}
      {showDialog?.share && (
        <Dialog
          title={"Share Form Template"}
          onClose={toggleDialog}
          width={"30%"}
        >
          <div>
            <div className="ready-to-book-appt-dialog">
              <div className="column-1-section">
                Email<span className="purchased-dots">:</span>
              </div>
              <div className="column-2-section">
                {props?.userDetails?.Email}
              </div>
              <div className="column-1-section">
                Enforce<span className="purchased-dots">:</span>
              </div>
              <div className="column-2-section">
                <Switch
                  checked={shareData?.Enforce}
                  onChange={(e) => handleChange(e.value, "Enforce")}
                />
              </div>
              <div className="column-1-section">
                Communication
                <span className="purchased-dots">:</span>
              </div>
              <div className="column-2-section">
                <DropDownList
                  data={
                    checkHasSMSEnable()
                      ? CommunicationPreferenceList
                      : CommunicationPreferenceOnlyEmail
                  }
                  textField="text"
                  //   className={`form-control col-md-12`}
                  dataItemKey="id"
                  style={{ width: "100%" }}
                  name="CommunicationPreference"
                  value={
                    CommunicationPreferenceList?.filter(
                      (i: any) => i?.id === shareData?.CommunicationPreference
                    )[0] || {
                      id: 0,
                      text: "Please select",
                    }
                  }
                  onChange={(e) =>
                    handleChange(e.value.id, "CommunicationPreference")
                  }
                />
              </div>
            </div>
            <div className="column-button-section book-appt-btns">
              <Button onClick={() => toggleDialog()} disabled={btnLoading}>
                {"Cancel"}
              </Button>
              <BuzopsButton
                label={"Send"}
                loading={btnLoading}
                disabled={btnLoading}
                onClick={() => handleShareForm()}
              />
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default withNotification(Forms);
