import withNotification from "components/Hoc/withNotification";
import React, { useEffect } from "react";
import { useApiClient } from "services/axios-service-utils";
import { Error, Label, Hint } from "@progress/kendo-react-labels";
import useState from "react-usestateref";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { PipeDriveService } from "services/pipedrive/index.service";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import BuzopsButton from "generic-components/BuzopsButton";
import { TenantService } from "services/tenant/index.service";
import { ListItemProps, MultiSelect } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
interface IGhlConfiguration {
  uuid?: any;
  from?: string;
  handleCRMSave: () => void;
  handleNotificationMessage: (text: any, type: string) => void;
}

const sortOptionsInArraybyPosition = (arrayItem: any) => {
  arrayItem.sort((a: any, b: any) => {
    return a.position - b.position;
  });
  return arrayItem;
};

const GhlConfiguration = ({
  handleCRMSave,
  uuid,
  from,
  handleNotificationMessage,
}: IGhlConfiguration) => {
  const { axiosRequest } = useApiClient();
  const service = new PipeDriveService();

  const [validateForm, setValidateForm] = useState(false);
  const [unLink, setUnlink] = useState(false);
  const [loading, setLoading] = useState(true);
  const [configurationData, setConfigurationData, configurationDataRef] =
    useState<any>({});
  const [pipelineData, setPipelineData, pipeLineDataRef] = useState<any>([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [unlinkButtonLoading, setUnlinkButtonLoading] = useState(false);
  const [validations, setValidations] = useState<any>([]);
  const [planData, setPlanData] = useState<any>([]);
  const [filteredPlanData, setFilteredPlanData] = useState<any>([]);
  const [pipeLineStages, setPipeLineStages] = useState<any>(null);

  useEffect(() => {
    fetchAllApis();
  }, []);

  const fetchAllApis = async () => {
    setLoading(true);
    await fetchGHLConfiguration();
    console.log(configurationDataRef?.current);
    if (configurationDataRef?.current) {
      await getPlanData();
      await getGHLPipeline();
      await fetchServiceStatus();
    }
    setLoading(false);
  };

  const fecthStagesWithPipelineId = (PipelineId: string) => {
    const getStages = pipeLineDataRef?.current?.find(
      (i: any) => i?.id === PipelineId
    )?.stages;
    return getStages || [];
  };

  const getPlanData = async () => {
    const req = {};
    const service = new TenantService();
    const res = await service.AvailablePlansToSubscribe(req);
    const result = res?.map((i: any) => {
      return { PlanManagementId: i?.PlanManagementId, Name: i?.Name };
    });
    setPlanData(result);
    setFilteredPlanData(result);
  };

  const fetchServiceStatus = async () => {
    const getServiceStatus = await service.getServiceStatus();
    const ProspectStatus = [
      {
        Description: "StageId",
        Label: "When a customer enrolls in Buzops as prospect",
        Id: 1,
      },
    ];
    const UnPaidStatus = [
      {
        Description: "UnPaidSubscriptionStageId",
        Label:
          "When a prospect enrolls in non-paid subscription(Complimentary, Consultation, Free Subscriptions)",
        Id: 1,
      },
    ];
    const res = { ...getServiceStatus, ProspectStatus, UnPaidStatus };
    setPipeLineStages(res);
  };

  const getGHLPipeline = async () => {
    await axiosRequest.get("CRM/GHL/GetPipelines", {
      successCallBack: (response: any) => {
        const data = response.data.Item.Items;
        const mainData = [...data, { id: 0, name: "Do Nothing", stages: [] }];
        setPipelineData(mainData || []);
        console.log("GetPipelines res  ", data);
      },
      errorCallBack: (error: any) => {
        setPipelineData([]);
        console.log(error?.response?.data?.Messages?.[0]);
      },
    });
  };

  const fetchGHLConfiguration = async () => {
    await axiosRequest.get("CRM/GHL/configuration", {
      successCallBack: async (response: any) => {
        const result = response.data?.Item || null;
        setConfigurationData(result);
        if (
          result?.AccessToken &&
          result?.AccessToken !== "" &&
          result?.AccessToken?.length > 0
        ) {
          setValidateForm(true);
          return true;
        } else {
          setValidateForm(false);
          return false;
        }
      },
      errorCallBack: (error: any) => {
        setConfigurationData(null);
        console.log(error?.response?.data?.Messages?.[0]);
      },
    });
  };

  const fetchsaGHLConfiguration = async () => {
    await axiosRequest.get("CRM/GHL/configuration", {
      successCallBack: async (response: any) => {
        const result = response.data?.Item || null;
        setConfigurationData(result);
        if (
          result?.AccessToken &&
          result?.AccessToken !== "" &&
          result?.AccessToken?.length > 0
        ) {
          setValidateForm(true);
          return true;
        } else {
          setValidateForm(false);
          return false;
        }
      },
      errorCallBack: (error: any) => {
        setConfigurationData(null);
        console.log(error?.response?.data?.Messages?.[0]);
      },
    });
  };

  const handleChange = async (
    val: any,
    name: string,
    parent: any = null,
    index?: number
  ) => {
    console.log(val, name, parent, index);
    if (parent === null) {
      //   const item = {
      //     ...configuration,
      //     [name]: val,
      //   };
      //   setConfiguration(item);
    } else {
      let item = { ...configurationDataRef?.current };
      if (name === "PlanPipelineId" || name === "PipelineId") {
        switch (name) {
          case "PlanPipelineId":
            const allPlanPipelines = [
              ...configurationDataRef?.current?.PlanPipelines,
            ];

            const res = allPlanPipelines?.map((i: any, indexVal) => {
              if (indexVal === index) {
                const obj: any = new Object();
                for (let keyItem in i) {
                  if (keyItem === "PlanPipelineId") {
                    obj[keyItem] = val;
                  } else {
                    obj[keyItem] = 0;
                  }
                }
                return obj;
              } else {
                return { ...i };
              }
            });
            item = {
              ...configurationDataRef?.current,
              [parent]: res,
            };

            break;

          case "PipelineId":
            const obj: any = new Object();
            if (parent === "ProspectPipeline") {
              for (let keyItem in item?.ProspectPipeline) {
                if (keyItem === "PipelineId") {
                  obj[keyItem] = val;
                } else {
                  obj[keyItem] = 0;
                }
              }
              item = {
                ...configurationDataRef?.current,
                [parent]: obj,
              };
            } else if (parent === "UnPaidSubscriptionPipeline") {
              for (let keyItem in item?.UnPaidSubscriptionPipeline) {
                if (keyItem === "PipelineId") {
                  obj[keyItem] = val;
                } else {
                  obj[keyItem] = 0;
                }
              }
              item = {
                ...configurationDataRef?.current,
                [parent]: obj,
              };
            }

            break;
          default:
            break;
        }
      } else {
        if (parent === "PlanPipelines") {
          const allPlanPipelines = [
            ...configurationDataRef?.current?.PlanPipelines,
          ];
          let value = val;
          if (name === "MembershipPlanIds") {
            value = val?.map((i: any) => i?.PlanManagementId);
          }
          const res = allPlanPipelines?.map((i: any, indexVal) => {
            if (indexVal === index) {
              return { ...i, [name]: value };
            } else {
              return { ...i };
            }
          });
          item = {
            ...configurationDataRef?.current,
            [parent]: res,
          };
        } else {
          item = {
            ...configurationDataRef?.current,
            [parent]: {
              ...configurationDataRef?.current[parent],
              [name]: val,
            },
          };
        }
      }
      setConfigurationData(item);
    }
  };

  const filterPlanChange = (e: any) => {
    const filteredData = filterData(e.filter, planData);
    setFilteredPlanData(filteredData);
  };

  const filterData = (filter: any, itemData: any) => {
    const data = itemData.slice();
    return filterBy(data, filter);
  };
  const handleValidation = (config: any, all = true) => {
    return [];
  };

  const handleSubmit = async () => {
    const validateData = handleValidation(configurationDataRef?.current, false);
    if (validateData.length > 0) {
      return true;
    } else {
      setButtonLoading(true);
      await axiosRequest.post(
        "CRM/GHL/configuration",
        configurationDataRef?.current,
        {
          successCallBack: async (response: any) => {
            setValidateForm(true);
            handleNotificationMessage(
              "GHL Configuration Successfully Saved",
              "success"
            );
            setButtonLoading(false)
            await fetchAllApis();
          },
          errorCallBack: (error: any) => {
            const errorMsg =
              error?.response?.data?.Messages?.[0] ||
              "Error in Saving GHL Configuration";
            handleNotificationMessage(errorMsg, "error");
            setButtonLoading(false)

          },
        }
      );
    }
  };
  
  const handleUnlinkGHL = async () => {
    setUnlinkButtonLoading(true);
      await axiosRequest.post(
        "CRM/GHL/Unlink",{},
        {
          successCallBack: async (response: any) => {
            setValidateForm(true);
            handleNotificationMessage(
              "GHL Configuration Successfully Unlinked",
              "success"
            );
            setUnlinkButtonLoading(false);
            handleCRMSave();
          },
          errorCallBack: (error: any) => {
            const errorMsg =
              error?.response?.data?.Messages?.[0] ||
              "Error in unlinking GHL Configuration";
            handleNotificationMessage(errorMsg, "error");
            setUnlinkButtonLoading(false);
          },
        }
      );
  };


  console.log(pipeLineStages, "pipeLineStages");
  console.log(configurationData, "configurationData");
  console.log(pipelineData, "pipelineData");

  return (
    <>
      {loading ? (
        <>
          <BuzopsLoader type={"list"} />
        </>
      ) : (
        <>
          <Card
            className="bz-pipedrive-container"
            style={{ background: "white", height: "auto" }}
          >
            <CardHeader className="pt-0">
              {!validateForm && (
                <CardTitle className="bz-card-title">
                  {"Configure GHL to connect"}
                </CardTitle>
              )}
            </CardHeader>
            <CardBody className="p-lg-4 pipedriveCard">
              {!validateForm ? (
                <div className="bz-pipedrive-url-row pb-3"></div>
              ) : (
                <div>
                  <BuzopsButton loading={unlinkButtonLoading} disabled={unlinkButtonLoading} onClick={()=> handleUnlinkGHL()} label={"Unlink"} />
                </div>
              )}

              {validateForm && (
                <>
                  <hr />
                  <div className="row pt-3 pb-3 prospect-blk">
                    <div className="col-12 mb-3">
                      <div className="form-group bz-form-group-row align-items-center">
                        <h6 className="h6">
                          {"Choose a pipeline for prospect"}
                          <small className="bz-ribbon-1">Pipeline</small>
                        </h6>
                        <div className="form-dropdown">
                          {console.log(pipelineData)}
                          <DropDownButton
                            textField={"name"}
                            items={pipelineData}
                            iconClass="fas fa-chevron-down"
                            text={
                              pipelineData.find(
                                (i: any) =>
                                  i?.id ===
                                  configurationDataRef?.current
                                    ?.ProspectPipeline?.PipelineId
                              )?.name
                            }
                            look="flat"
                            className={"bz-white-btn bz-dropdown-btn px-0"}
                            onItemClick={(e: any) => {
                              handleChange(
                                e?.item?.id,
                                "PipelineId",
                                "ProspectPipeline"
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-3">
                      {pipeLineStages?.ProspectStatus?.map((item: any) => {
                        let itemName = item?.Description;
                        const Pipeline = fecthStagesWithPipelineId(
                          configurationDataRef?.current?.ProspectPipeline
                            ?.PipelineId
                        );
                        const PipelinesStages = [
                          ...Pipeline,
                          { id: 0, name: "Do Nothing" },
                        ];
                        return (
                          <>
                            <div className="stage-list">
                              <div className="form-group bz-form-group-row align-items-center pipedrive-stage">
                                <Label className="form-label mb-0">
                                  {item?.Label}
                                  <small className="bz-ribbon-1 stage">
                                    Stage
                                  </small>
                                  {configurationDataRef?.current
                                    ?.ProspectPipeline?.[itemName] === 0 && (
                                    <small>
                                      <Hint>
                                        {
                                          "Clients will be created in pipedrive as contacts when selected do nothing"
                                        }
                                      </Hint>
                                    </small>
                                  )}
                                </Label>
                                <div className="form-dropdown">
                                  <DropDownButton
                                    textField={"name"}
                                    items={PipelinesStages}
                                    iconClass="fas fa-chevron-down"
                                    text={
                                      PipelinesStages?.find(
                                        (i: any) =>
                                          i?.id ===
                                          configurationDataRef?.current
                                            ?.ProspectPipeline?.[itemName]
                                      )?.name
                                    }
                                    look="flat"
                                    className={
                                      "bz-white-btn bz-dropdown-btn px-0"
                                    }
                                    onItemClick={(e: any) => {
                                      handleChange(
                                        e?.item?.id,
                                        itemName,
                                        "ProspectPipeline"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <hr />
                  </div>
                  {configurationDataRef?.current?.PlanPipelines?.length > 0 && <h6 className="h6">
                    {"Choose a pipeline for plan subscription"}
                    <small className="bz-ribbon-1">Pipeline</small>
                  </h6>}
                  {configurationDataRef?.current?.PlanPipelines?.map(
                    (config: any, index: number) => {
                      const PlanPipeline = fecthStagesWithPipelineId(
                        config?.PlanPipelineId
                      );
                      const PlanPipelinesStages = [
                        ...PlanPipeline,
                        { id: 0, name: "Do Nothing" },
                      ];
                      let membershipValues = [];
                      if (config?.MembershipPlanIds?.length > 0) {
                        membershipValues = planData?.filter((i: any) => {
                          const findItem = config?.MembershipPlanIds?.find(
                            (j: any) => j === i?.PlanManagementId
                          );
                          if (findItem) {
                            return true;
                          }
                          return false;
                        });
                      }
                      return (
                        <div className="row mb-3 subscription-blk">
                          <div className="col-12 mb-3">
                            <div className="form-group bz-form-group-row align-items-center">
                              <div>
                                <MultiSelect
                                  data={planData}
                                  textField="Name"
                                  dataItemKey="PlanManagementId"
                                  placeholder="Please select Plans"
                                  onChange={(e) => {
                                    handleChange(
                                      e.value,
                                      "MembershipPlanIds",
                                      "PlanPipelines",
                                      index
                                    );
                                  }}
                                  filterable={true}
                                  onFilterChange={(e) => filterPlanChange(e)}
                                  //   itemRender={itemRender}
                                  value={membershipValues || []}
                                  className="bz-multiselect-arrow"
                                />
                              </div>
                              <div className="form-dropdown">
                                <DropDownButton
                                  textField={"name"}
                                  items={pipelineData}
                                  iconClass="fas fa-chevron-down"
                                  text={
                                    pipelineData.find(
                                      (i: any) =>
                                        i?.id === config?.PlanPipelineId
                                    )?.name
                                  }
                                  look="flat"
                                  className={
                                    "bz-white-btn bz-dropdown-btn px-0"
                                  }
                                  onItemClick={(e: any) => {
                                    handleChange(
                                      e.item?.id,
                                      "PlanPipelineId",
                                      "PlanPipelines",
                                      index
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-12 mb-3">
                            {pipeLineStages?.PlanStatus?.map((item: any) => {
                              //   let itemName = item?.Description
                              let itemName = item?.Description?.replace(
                                /[^a-zA-Z ]/g,
                                ""
                              );
                              itemName = `${itemName}StageId`;
                              console.log(itemName, "itemName");
                              return (
                                <>
                                  <div className="stage-list">
                                    <div className="form-group bz-form-group-row align-items-center pipedrive-stage">
                                      <Label className="form-label mb-0">
                                        {item?.Label}
                                        <small className="bz-ribbon-1 stage">
                                          Stage
                                        </small>
                                      </Label>
                                      <div className="form-dropdown">
                                        <DropDownButton
                                          textField={"name"}
                                          items={PlanPipelinesStages}
                                          iconClass="fas fa-chevron-down"
                                          text={
                                            PlanPipelinesStages?.find(
                                              (i: any) =>
                                                i?.id === config?.[itemName]
                                            )?.name
                                          }
                                          look="flat"
                                          className={
                                            "bz-white-btn bz-dropdown-btn px-0"
                                          }
                                          onItemClick={(e: any) => {
                                            handleChange(
                                              e.item?.id,
                                              itemName,
                                              "PlanPipelines",
                                              index
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                          <hr />
                        </div>
                      );
                    }
                  )}
                </>
              )}
            </CardBody>
            <div>
              {from && (
                <div className="py-2">
                  <div className="section-1 btn-container align-self-center justify-self-start">
                    {validateForm && (
                      <BuzopsButton
                        onClick={() => handleSubmit()}
                        primary
                        disabled={buttonLoading}
                        type={"submit"}
                        loading={buttonLoading}
                        label={"Save"}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </Card>
        </>
      )}
    </>
  );
};

export default withNotification(GhlConfiguration);
