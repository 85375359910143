import React, { useState } from "react";
import MyLinks from "./MyLinks";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { CapitalizeStr } from "utils";
import FormLinks from "./FormLinks";

const AffiliateLinks = (props: any) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabSelect = (tabValue: number) => {
    setSelectedTab(tabValue);
  };

  const tabs = [
    {
      title: "My Links",
      id: 1,
      name: "MyLinks",
    },
    {
      title: "Form Links",
      id: 2,
      name: "FormLinks",
    },
  ];

  return (
    <div className="row justify-content-center bz-tabstrip">
      <TabStrip
        selected={selectedTab}
        onSelect={(e) => handleTabSelect(e.selected)}
      >
        {tabs.map((item, index) => {
          return (
            <TabStripTab title={`${CapitalizeStr(item.title)}`} key={index}>
              {item.id === 1 && <MyLinks {...props} />}
              {item.id === 2 && <FormLinks {...props} />}
            </TabStripTab>
          );
        })}
      </TabStrip>
    </div>
  );
};

export default AffiliateLinks;
