import React from 'react'
import DragHandle from './DragHandle'

const HeaderBar = (props:any) => {
  return (
    <div className="toolbar-header">
    {/* <span className="badge badge-secondary">{props?.data.text}</span> */}
    <div className="toolbar-header-buttons">
      {props?.data.element !== 'LineBreak' && props?.data.element !== 'Address' && props?.data.element !== 'Prospect' &&
        <div className="btn is-isolated" onClick={(e:any)=>props?.editModeOn(props?.data,e)}><i className="is-isolated fas fa-edit editformbuzops"></i></div>
      }
      {!props?.data?.parentId &&  <div className="btn is-isolated" onClick={()=>props?.onDestroy(props?.data)}><i className="is-isolated fas fa-trash-alt deleteformbuzops"></i></div>}
      {!props?.data?.parentId && <DragHandle data={props?.data} index={props?.index} onDestroy={props?.onDestroy} setAsChild={props?.setAsChild} />}
    </div>
  </div>
  )
}

export default HeaderBar
