import withNotification from "components/Hoc/withNotification";
import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useEffect, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { useApiClient } from "services/axios-service-utils";
import {
  FormTemplateCategoryIntEnum,
  FormTemplateStatusIntEnum,
} from "utils/form-utils";
import SharableLinksDialog from "./SharableLinkDialog";
import { GetLocalStore } from "utils/storage";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { AffiliateProgramService } from "services/affiliateprogram/index.service";

const FormLinks = (props: any) => {
  const [promotions, setPromotions] = useState([]);
  const [selectedPromotion, setSelectedPromotion] = useState<any>(null);
  const service = new AffiliateProgramService();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { axiosRequest } = useApiClient();
  const [showDialog, setShowDialog] = React.useState(false);
  const [selectedLink, setSelectedLink] = React.useState({
    url: "",
    name: "",
  });

  const handleSelectLink = (link: string, name: string) => {
    setSelectedLink({
      url: link,
      name,
    });
    setShowDialog(true);
  };

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  useEffect(() => {
    getPromotions();
    fetchFormsList();
  }, []);

  const getPromotions = async () => {
    const req = {
      AffiliateId: parseInt(props?.associateId),
      PromotionType:2
    };
    setLoading(true);
    const res = await service.getPromotions(req);
    setPromotions(res?.Items || []);
    setSelectedPromotion(res?.Items?.[0]);
    setLoading(false);
  };

  const handleChange = (dataItem: any) => {
    setSelectedPromotion(dataItem);
  };

  const errorCallBack = (message: string) => {
    props?.handleNotificationMessage(message, "error");
  };
  const copyToClipBoard = (text: any) => {
    navigator.clipboard.writeText(text);
    props?.handleNotificationMessage("Copied", "success");

  };

  const fetchFormsList = async () => {
    let req: any = {
      SortDirection: "ASC",
      SortBy: "FormName",
      PageSize: 32767,
      PageNumber: 0,
      Status: FormTemplateStatusIntEnum["PUBLISHED"],
      FormCategory: FormTemplateCategoryIntEnum["REFERRAL"],
    };
    setLoading(true);
    await axiosRequest.post("Forms/FormTemplate/filter", req, {
      successCallBack: (response: any) => {
        setData(response.data?.Items || []);
        setLoading(false);
      },
      errorCallBack: (response: any) => {
        setLoading(false);
        errorCallBack(
          response?.response?.data?.Messages?.[0] || "Internal server error"
        );
      },
    });
  };
  let DomainName = GetLocalStore("tenantUserSession")?.DomainName;
  if (!props?.uuid) {
    DomainName = GetLocalStore("AffiliateDetails")?.DomainName;
  }

  return (
    <div className="promotion-overview">
      <p className="text-muted"> Share the form link to create prospects.</p>
      <div className="col-lg-3 reports-date-range pl-1 col-12 col-md-4">
          <DropDownList
            data={promotions}
            textField="Name"
            dataItemKey="PromotionId"
            name="PromotionId"
            label="Select a Promotion"
            style={{ width: "100%" }}
            value={
              promotions?.filter((i: any) => i?.PromotionId === selectedPromotion?.PromotionId)?.[0] || {
                PromotionId: 0,
                Name: "Please Select Promotion",
              }
            }
            onChange={(e) => handleChange(e.value)}
          />
        </div>
      <div className="row mt-3">
        <div className="col-lg-4 col-md-6 links d-flex align-items-stretch">
          <div className=" summary-card-payout w-100  mb-4">
            <h5 className="affiliatemainheading pb-2">Referral Forms</h5>
            <div
              className="linkdescriptions"
              style={{
                maxHeight: "500px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <div className="mt-3">
                {loading ? (
                  <BuzopsLoader type="list" />
                ) : (
                  <div>
                    {data?.length === 0 ? (
                      <div
                        className={
                          "bz-no-upcoming-appoinments text-muted bz-upcomingappointments-card"
                        }
                      >
                        <div className="bz-norecords-available">
                          No Links Available
                        </div>
                      </div>
                    ) : (
                      <>
                        {data?.map((i: any) => {
                          const link = `https://${DomainName}.${process.env.REACT_APP_DOMAIN}.com/form/referral/${selectedPromotion?.ShortCode}/${i?.ShortCode}/${props?.affiliateDetails?.AffiliateCode}`;

                          return (
                            <div
                              className="d-flex align-items-center mb-2"
                              key={i?.FormTemplateId}
                            >
                              <div className="flex-grow-1">
                                <div
                                  className="d-flex align-items-center linkbg align-items-center"
                                  style={{ gap: "10px" }}
                                >
                                  <div
                                    className="flex-grow-1"
                                    style={{ wordBreak: "break-all" }}
                                  >
                                    {" "}
                                    <a href={link} target="_blank">
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                        className="align-items-center"
                                      >
                                        <div>
                                          {" "}
                                          <i
                                            className="fa fa-link"
                                            style={{
                                              fontSize: "11px",
                                              marginRight: "5px",
                                            }}
                                          ></i>
                                        </div>
                                        <div style={{ flexGrow: "1" }}>
                                          {" "}
                                          {i?.FormName}
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div
                                    className="flex-shrink-0"
                                    style={{ width: "20px" }}
                                  >
                                    <span className="bz-cursor-pointer">
                                      <MdContentCopy
                                        onClick={() => copyToClipBoard(link)}
                                        title={"Copy"}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="flex-shrink-0 px-2">
                                {" "}
                                <a
                                  href="#"
                                  onClick={() =>
                                    handleSelectLink(link, i?.FormName)
                                  }
                                  className="btn btn-primary-buzops btn-sm text-white"
                                >
                                  {" "}
                                  <em className="mdi mdi-share-variant-outline"></em>
                                </a>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDialog && <SharableLinksDialog {...props} copyToClipBoard={copyToClipBoard} selectedLink={selectedLink} toggleDialog={toggleDialog} isForm={true} />}
    </div>
  );
};

export default withNotification(FormLinks);
